<script>
  import { updateSession, getRegion, fetchJSON } from "../../utils.js";
  import { params, redirect } from "@roxi/routify";
  import { getLocaleFromNavigator } from "svelte-i18n";
  import Swal from "sweetalert2";
  import { Circle3 } from "svelte-loading-spinners";

  export let scoped;
  const { countries, prices, session, countryCode, lang } = scoped;
  const { product, variant = "full", user_id } = $params;
  let isLoading = true;
  let errorMessage = "Loading ...";
  function defaultLanguage() {
    let localeFromBrowser = getLocaleFromNavigator();
    let localeFromStorage = localStorage.getItem("enrol-app-locale");
    let localeDefault = "en";

    if (lang) {
      return lang;
    }

    if (localeFromStorage) {
      return localeFromStorage;
    }

    if (localeFromBrowser) {
      return localeFromBrowser.slice(0, 2);
    }

    return localeDefault;
  }

  let user_exists;
  function showSwalError() {
    isLoading = false;
    errorMessage =
      '<b>⚠️ Sorry, you must subscribe from within your profile in the Hearth: <a target="_blank" href="https://hearth.druidry.org">https://hearth.druidry.org</a></b>';
    Swal.fire({
      icon: "error",
      title: "Oops...",
      html: '<b>⚠️ Sorry, you must subscribe from within your profile in the Hearth: <a target="_blank" href="https://hearth.druidry.org">https://hearth.druidry.org</a></b>',
    });
  }
  async function verify_wordpress_user(id) {
    await fetchJSON(`/api/v2/wordpress-user/exists?id=${id}`)
      .then((data) => {
        user_exists = data.exist;
        if (user_exists) {
          localStorage.setItem("hearth-online-user_id", id);
          goToProductPage();
        } else {
          console.error("please user a correct wordpress user id!");
        }
      })
      .catch((error) => {
        user_exists = false;
        showSwalError();
      });
  }

  if (user_id) {
    verify_wordpress_user(user_id);
  } else {
    showSwalError();
  }

  function goToProductPage() {
    if (product) {
      let language = defaultLanguage();
      localStorage.setItem("enrol-app-locale", language);
      const region = getRegion(countries, countryCode);
      let regionalPrices = [];
      if (language == "en") {
        let location = "GB";
        if (region == "EU" || region == "ROW") {
          location = "ROW";
        }
        regionalPrices = prices.filter(
          (price) =>
            price.language === language && price.region.code === location
        );
      } else {
        regionalPrices = prices.filter((price) => price.language === language);
      }
      const productPrices = regionalPrices.filter(
        (price) => price.variant.product.code === product
      );
      const [price] = productPrices.filter(
        (price) => price.variant.code === variant
      );
      if (price)
        updateSession(session, {
          selectedPriceID: price.id,
          selectedPriceRegionCode: price.region.code,
        });
    }

    $redirect("./package");
  }
</script>

<div style="text-align: center">
  {#if isLoading}
    <div class="circle3-spinner">
      <Circle3 size="60" unit="px" duration="1s" />
    </div>
  {/if}
  {@html errorMessage}
</div>

<style>
  .circle3-spinner {
    margin-left: 45%;
  }
</style>
