<script>
  import { updateSession, getPrice, getRegion } from "../../utils.js";
  import { goto, url } from "@roxi/routify";

  import CountryName from "../../components/CountryName.svelte";
  import CurrencyConverter from "../../components/CurrencyConverter.svelte";
  import PriceDisplay from "../../components/PriceDisplay.svelte";
  import ProductSelect from "../../components/ProductSelect.svelte";
  import VariantSelect from "../../components/VariantSelect.svelte";
  import ContactUsForm from "../../components/ContactUsForm.svelte";

  export let scoped;
  const {
    countries,
    symbols,
    rates,
    prices,
    session,
    countryCode,
    countryCodeIsSelected,
  } = scoped;

  const regionalPrices = prices;
  const initialPrice = getPrice(regionalPrices, $session.selectedPriceID);

  let product;
  product = initialPrice ? initialPrice.variant.product : null;

  let variant;
  variant = initialPrice ? initialPrice.variant : null;

  let price, annual;
  $: {
    price =
      product && variant
        ? regionalPrices.find((price) => price.variant.id === variant.id)
        : null;
    annual = regionalPrices.find((price) => price.variant.code === "annually");
  }

  let toggleCurrencyConverter;
  let isOpen = false;

  const productDetails = {
    online: {
      description: "Online",
      blurb: `Available to all members of the Order of Bards, Ovates & Druids (OBOD) <br><br>
      – you may subscribe here to The Druid Hearth – our online community when you can connect, communicate & engage with friends and companions on the Druid path.<br><br>
      A full subscription gives you ongoing access to the Druid Hearth Community:<br>
      • View community Activity Feed<br>
      • Access Circles & private groups<br>
      • Direct member messaging<br>
      • Live Event Access<br>
      • Touchstone (including
        back issues)<br>
      • Events Calendar & Listings<br>
      • + Other exclusive online
        & new features!`,
      type: "hearth-",
    },
  };

  function formSubmit() {
    updateSession(session, {
      selectedPriceID: price.id,
      selectedPriceRegionCode: price.region.code,
      isConsentGiven: true,
    });
    $goto("./details");
  }
</script>

<form on:submit|preventDefault={formSubmit}>
  <section>
    <h2>Greetings!</h2>
    <p class="space-under">
      Members of OBOD may setup their full subscription to all the services &
      features of The Druid Hearth here:
    </p>

    <ProductSelect
      prices={regionalPrices}
      details={productDetails}
      isHearth={true}
      bind:product
    />
  </section>

  <VariantSelect prices={regionalPrices} bind:variant isHearth={true} />

  <section class="links">
    <div class="change-location">
      <p>
        All our prices in GBP based on your
        {#if countryCodeIsSelected}
          selected
        {:else}
          detected
        {/if}
        location in: <CountryName {countries} {countryCode} />
      </p>
      <a href={$url("./country")}>&gt; Change my location</a>
    </div>

    {#if price}
      <div class="currency-converter">
        <p>Click here to see this price in another currency.</p>
        <button on:click|preventDefault={toggleCurrencyConverter}
          >Currency Converter</button
        >
      </div>
    {/if}

    <button
      class="open"
      on:click|preventDefault={() => {
        isOpen = true;
      }}>> Feedback / Problems? Contact us</button
    >

    <br /><br /><br /><br />
  </section>

  <CurrencyConverter
    bind:toggle={toggleCurrencyConverter}
    {countries}
    {symbols}
    {rates}
    {price}
    {countryCode}
  />

  <ContactUsForm bind:isOpen />

  <section class="btn-container">
    <div class="btn-container__inside">
      <PriceDisplay {annual} {price} code={"hearth"} />
      <button disabled={!price} class="btn btn--primary">Next</button>
    </div>
  </section>
</form>

<style>
  @import "../../styles/variables.css";

  .space-under {
    margin-bottom: 1rem;
  }

  .links {
    margin-top: 2em;
  }

  .change-location,
  .currency-converter,
  .course-companion-link {
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
  }

  .currency-converter button {
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
  }

  .currency-converter button::before {
    content: "> ";
  }

  .currency-converter button:hover {
    cursor: pointer;
  }

  .open {
    margin-bottom: var(--baseline);
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
    cursor: pointer;
  }
</style>
