<!-- routify:options bundle=true -->
<script>
  export let scoped;

  import {
    blankCustomerDetails,
    fetchJSON,
    loadOrInitialiseSession,
    getCountryCode,
    getPrice,
    getAccessibleStages,
  } from "../../utils.js";
  import { loadingCount } from "../../stores.js";
  import { params } from "@roxi/routify";

  import Error from "../../components/Error.svelte";
  import Header from "../../components/Header.svelte";
  import Steps from "../../components/Steps.svelte";

  import Swal from "sweetalert2";
  import { register, locale, init } from "svelte-i18n";
  import { getServerState } from "../../utils";

  const { user_id } = $params;
  let user_exists = false;

  function showSwalError() {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      html: '<b>⚠️ Sorry, you must subscribe from within your profile in the Hearth: <a target="_blank" href="https://hearth.druidry.org">https://hearth.druidry.org</a></b>',
    });
  }

  register("en", () => import("../../../public/lang/en.json"));
  locale.set("en");
  localStorage.setItem("enrol-app-locale", "en");
  init({
    fallbackLocale: "en",
    initialLocale: "en",
  });

  let language;
  if (localStorage.getItem("enrol-app-locale")) {
    language = localStorage.getItem("enrol-app-locale");
  } else {
    language = "en";
  }

  let maintenance_message;
  let enabled;
  getServerState(language).then((res) => {
    if (res.enabled_maintenance_page) {
      maintenance_message = res.maintenance_page;
      enabled = res.enabled_maintenance_page;
    }
  });

  const { loading: parentLoading } = scoped;

  const session = loadOrInitialiseSession("hearth", {
    selectedPriceID: null,
    customerDetails: blankCustomerDetails(),
    selectedCountryCode: null,
  });

  let prices, props;
  $: {
    const price = prices && getPrice(prices, $session.selectedPriceID);
    props = {
      ...scoped,
      price,
      prices,
      session,
      countryCode: getCountryCode({
        detectedCountryCode: scoped.detectedCountryCode,
        selectedCountryCode: $session.selectedCountryCode,
      }),
      accessibleStages: getAccessibleStages($session),
      countryCodeIsSelected: $session.selectedCountryCode !== null,
    };
  }

  async function verify_wordpress_user(id) {
    await fetchJSON(`/api/v2/wordpress-user/exists?id=${id}`)
      .then((data) => {
        user_exists = data.exist;
        if (user_exists) {
          localStorage.setItem("hearth-online-user_id", id);
        } else {
          console.error("please user a correct wordpress user id!");
        }
      })
      .catch((error) => {
        console.log(error);
        user_exists = false;
      });
  }

  if (user_id) {
    verify_wordpress_user(user_id);
  } else {
    showSwalError();
  }

  const loading = Promise.all([
    parentLoading,
    fetchJSON("/api/v1/shops/hearth/prices").then(({ data }) => {
      prices = data;
    }),
  ]);

  loadingCount.update((n) => n + 1);
  loading.finally(() => loadingCount.update((n) => n - 1));
</script>

{#if !enabled}
  <Header title="The Druid Hearth - Subscription Setup">
    <Steps />
  </Header>
{/if}

{#await loading && !user_exists}
  <!-- Loading -->
{:then}
  <main>
    {#if enabled}
      {@html maintenance_message}
    {:else}
      <slot scoped={props} />
    {/if}
  </main>
{:catch error}
  <Error {error} />
{/await}
