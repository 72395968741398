<script>
  import { updateSession } from "../../utils.js";
  import { goto, redirect } from "@roxi/routify";
  import { _ } from "svelte-i18n";

  import DetailsForm from "../../components/DetailsForm.svelte";
  import OrderSummary from "../../components/OrderSummary.svelte";

  export let scoped;
  const { countries, price, session, countryCode, accessibleStages } = scoped;
  const accessible = accessibleStages.includes("details");
  if (!accessible) $redirect("./package");
  let descriptionText = $_("details.description_text");
  let invitationData = $session.invitationData;

  function formSubmit({ customerDetails }) {
    updateSession(session, { customerDetails });
    $goto("./payment");
  }
</script>

{#if accessible}
  <OrderSummary {countries} {countryCode} {price} {descriptionText} />
  <DetailsForm
    {countries}
    {countryCode}
    {price}
    {invitationData}
    customerDetails={$session.customerDetails}
    {formSubmit}
    isMember={true}
  />
{/if}
